const CHINA_LOCALES = ['ch_cn']
const FRANCE_LOCALES = ['fr']
const SPAIN_LOCALES = ['es']
const GERMANY_LOCALES = ['de']
const USA_LOCALES = ['us_en']
const ARABIC_LOCALES = ['ar_ar', 'sa_ar']
const COTEIVOIRE_LOCALES = ['ci_fr', 'ci_en']
const UAE_LOCALES = ['ar_en', 'ar_ar']
const UK_LOCALES = ['uk_en']
const JAPAN_LOCALES = ['jp_ja', 'jp_en']
const SWISS_LOCALES = ['su_en', 'su_fr']
const EUROPE_WITHOUT_GERMANY_LOCALES = FRANCE_LOCALES.concat(SPAIN_LOCALES, 'en', 'it')

export const getIsChinaStore = (locale: string) => CHINA_LOCALES.includes(locale)

// fr, ci_fr, su_fr
export const getIsStoreWithFrenchLanguage = (locale: string) => locale.includes('fr')

export const getIsFranceLocale = (locale: string) => FRANCE_LOCALES.includes(locale)

export const getIsSpainLocale = (locale: string) => SPAIN_LOCALES.includes(locale)

export const getIsGermanyLocale = (locale: string) => GERMANY_LOCALES.includes(locale)

export const getIsUSALocale = (locale: string) => USA_LOCALES.includes(locale)

export const getIsChinaLocale = (locale: string) => CHINA_LOCALES.includes(locale)

export const getIsArabicLocale = (locale: string) => ARABIC_LOCALES.includes(locale)

export const getIsCoteIvoire = (locale: string) => COTEIVOIRE_LOCALES.includes(locale)

export const getIsUKLocale = (locale: string) => UK_LOCALES.includes(locale)

export const getIsJapanLocale = (locale: string) => JAPAN_LOCALES.includes(locale)

export const getIsSwissLocale = (locale: string) => SWISS_LOCALES.includes(locale)

export const getIsUaeLocale = (locale: string) => UAE_LOCALES.includes(locale)

export const getIsEuropeWithoutGermanyLocale = (locale: string) =>
  EUROPE_WITHOUT_GERMANY_LOCALES.includes(locale)
